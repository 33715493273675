<template>
  <div>
  <FullScreenDialog
    v-model="paymentDebitDialog"
    title="Nuovo storno"
    :routeFather="pathToGoBack"
  >
    <template v-slot:toolbar-extension>
      <v-tabs v-model="tab" align-with-title>
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab
          v-for="tab in paymentDebitTabs"
          :key="tab.key"
        >{{ tab.title }}</v-tab>
      </v-tabs>
    </template>
    <template v-slot:content>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="tabItem in paymentDebitTabs" :key="tabItem.key">
          <v-card flat>
            <component :is="tabItem.component"></component>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </template>
    <template v-slot:footer-actions>
      <v-spacer></v-spacer>
      <v-btn
        text
        color="error"
        @click="$router.push({path: pathToGoBack})"
      >Annulla</v-btn>
      <v-btn
        text
        color="default"
        :disabled="!formValid"
        :loading="loadingSubmit"
        @click="chooseCardOrCash"
      >Salva</v-btn>
    </template>
  </FullScreenDialog>
  <StandardDialog
    v-model="choosePaymentDebitMethodDialog"
    :dialog-height="null"
    dialog-width="40vw"
    title="Scegli Metodo Di Storno"
  >
    <v-alert
      border="left"
      colored-border
      color="warning"
      elevation="2"
      style="width: 100%"
      class="mb-2 mt-2"
    >
      <div class="text-center">
        Scegliere il tipo di rimborso
      </div>
    </v-alert>
    <template v-slot:footer-actions>
      <v-btn
        text
        color="error"
        @click="choosePaymentDebitMethodDialog = false"
      >
        Chiudi
      </v-btn>
      <v-btn
        text
        color="default"
        @click="submitCashDebit"
        :loading="loadingSubmit"
      >
        Contanti
      </v-btn>
      <v-btn
        text
        color="default"
        @click="submitVoucherDebit"
        :loading="loadingSubmit"
      >
        Voucher
      </v-btn>
      <v-btn
        text
        color="default"
        @click="submitCardDebit"
        :loading="loadingSubmit"
        :disabled="!customerHasCard"
      >
        Tessera
      </v-btn>
    </template>
  </StandardDialog>
  </div>    
</template>

<script>
import FullScreenDialog from "@/components/common/FullScreenDialog.vue";
import GeneralForm from "./form/GeneralFormTab.vue"
import paymentDebitsService from '@/services/paymentDebits/paymentDebits.service.js'
import paymentDebitForm from '@/services/paymentDebits/paymentDebit.form.js'
import cashDeskService from "@/services/cashDesks/cashDesks.service.js";
import customerCardService from '@/services/customers/customerCards.service.js'
import StandardDialog from '@/components/common/StandardDialog.vue'
import Printer from '@/services/devices/rtPrinter'

export default {
  name: "PaymentTransactionsCollectedNewForm",
  components: {
    FullScreenDialog,
    GeneralForm,
    StandardDialog,
  },
  data: function() {
    return {
      tab: null,
      paymentDebitDialog: this.openDialog,
      paymentDebitTabs: [
        {
          key: 0,
          title: "Generale",
          name: "general",
          component: "GeneralForm"
        }
      ],
      formValid: false,
      paymentDebit: undefined,
      originalUrl: window.location.pathname,
      choosePaymentDebitMethodDialog: false,
      activeCardId: undefined,
      loadingSubmit: false,
      customerHasCard: false,
      taxForSaloon: undefined
    };
  },
  mounted() {
    this.changeTabBasedOnProp(this.tabName)

    let self = this
    paymentDebitForm.resetPaymentDebit()
    paymentDebitForm.on('update', function(data) {
      self.paymentDebit = data.paymentDebit
    })

    paymentDebitForm.on('valid-change', function(data) {
      self.formValid = data
    })
  },
  props: {
    openDialog: {
      type: Boolean,
      default: false
    },
    pathToGoBack: {
      type: String,
      default: "/payments"
    },
    tabName: {
      type: String,
      default: 'general'
    }
  },
  methods: {
    changeTabBasedOnProp(tabName) {
      for(let i = 0; i < this.paymentDebitTabs.length; i++) {
        if(this.paymentDebitTabs[i].name == tabName) {
          this.tab = i
          break
        }
      }
    },
    async chooseCardOrCash(){
      if(!this.paymentDebit.customerId) {
        this.choosePaymentDebitMethodDialog = true
        return
      }

      try {
        const result = await customerCardService.checkActiveCardToCustomer(this.paymentDebit.customerId)
        if(!!result && result.length > 0){
          this.customerHasCard = true
          this.activeCustomerCard = result[0]
        }
        this.choosePaymentDebitMethodDialog = true
      } catch (error) {
        console.log(error)
      }
      
    },
    submitCashDebit() {
      this.loadingSubmit = true
      cashDeskService.getOpened().then(async (cashDesk) => {
        this.paymentDebit.cashDesk = cashDesk
        this.paymentDebit.cashDeskLog = cashDesk.cashDeskLog.id
        this.paymentDebit.activeCustomerCard = false
        let documentNumberFixed = undefined

        if(!!this.paymentDebit.documentNumber){
          this.paymentDebit.tax = this.taxForSaloon === undefined || this.taxForSaloon === null ? '22%' : this.taxForSaloon + '%'

          let rtResponse = await Printer.printPaymentDebit(this.paymentDebit)
          if(!!rtResponse && !!rtResponse.addInfo){
            rtResponse.addInfo.zRepNumber = '' + rtResponse.addInfo.zRepNumber
            rtResponse.addInfo.fiscalReceiptNumber = '' + rtResponse.addInfo.fiscalReceiptNumber
            while(rtResponse.addInfo.zRepNumber.length < 4){
              rtResponse.addInfo.zRepNumber = '0' + rtResponse.addInfo.zRepNumber
            }
            while(rtResponse.addInfo.fiscalReceiptNumber.length < 4){
              rtResponse.addInfo.fiscalReceiptNumber = '0' + rtResponse.addInfo.fiscalReceiptNumber
            }
            if (!!rtResponse.addInfo.zRepNumber && rtResponse.addInfo.zRepNumber != 'undefined' 
              && !!rtResponse.addInfo.fiscalReceiptNumber && rtResponse.addInfo.fiscalReceiptNumber != 'undefined')
              documentNumberFixed = rtResponse.addInfo.zRepNumber + '-' + rtResponse.addInfo.fiscalReceiptNumber
          }
          this.paymentDebit.documentNumber = documentNumberFixed
        }
        paymentDebitsService.createAndPay(this.paymentDebit).then((result) => {
          this.loadingSubmit = false
          this.choosePaymentDebitMethodDialog = false
          this.$router.push({path: this.pathToGoBack})
        })
      })
    },
    submitCardDebit() {
      this.loadingSubmit = true
      cashDeskService.getOpened().then((cashDesk) => {
        this.paymentDebit.cashDesk = cashDesk
        this.paymentDebit.cashDeskLog = cashDesk.cashDeskLog.id
        this.paymentDebit.activeCustomerCard = this.activeCustomerCard
        delete this.paymentDebit.documentNumber
        paymentDebitsService.createAndPay(this.paymentDebit).then((result) => {
          this.loadingSubmit = false
          this.choosePaymentDebitMethodDialog = false
          this.$router.push({path: this.pathToGoBack})
        })
      })
    },
    submitVoucherDebit(){
      this.loadingSubmit = true 
      cashDeskService.getOpened().then(async (cashDesk) => {
        this.paymentDebit.cashDesk = cashDesk
        this.paymentDebit.cashDeskLog = cashDesk.cashDeskLog.id
        this.paymentDebit.activeCustomerCard = false
        let documentNumberFixed = undefined
        this.paymentDebit.voucher = true
        if(!!this.paymentDebit.documentNumber){
          let rtResponse = await Printer.printPaymentDebit(this.paymentDebit)
          await Printer.printVoucherReport(this.paymentDebit.amount)
          if(!!rtResponse && !!rtResponse.addInfo){
            rtResponse.addInfo.zRepNumber = '' + rtResponse.addInfo.zRepNumber
            rtResponse.addInfo.fiscalReceiptNumber = '' + rtResponse.addInfo.fiscalReceiptNumber
            while(rtResponse.addInfo.zRepNumber.length < 4){
              rtResponse.addInfo.zRepNumber = '0' + rtResponse.addInfo.zRepNumber
            }
            while(rtResponse.addInfo.fiscalReceiptNumber.length < 4){
              rtResponse.addInfo.fiscalReceiptNumber = '0' + rtResponse.addInfo.fiscalReceiptNumber
            }
            if (!!rtResponse.addInfo.zRepNumber && rtResponse.addInfo.zRepNumber != 'undefined' 
              && !!rtResponse.addInfo.fiscalReceiptNumber && rtResponse.addInfo.fiscalReceiptNumber != 'undefined')
              documentNumberFixed = rtResponse.addInfo.zRepNumber + '-' + rtResponse.addInfo.fiscalReceiptNumber
          }
          this.paymentDebit.documentNumber = documentNumberFixed
        }
        paymentDebitsService.createAndPay(this.paymentDebit).then((result) => {
          this.loadingSubmit = false
          this.choosePaymentDebitMethodDialog = false
          this.$router.push({path: this.pathToGoBack})
        })
      })
    }
  },
  watch: {
    openDialog(newVal) {
      this.paymentDebitDialog = newVal;
    },
    tab(newVal) {
      try {
        window.history.pushState("Tab", "Tab", this.originalUrl + '?tabName=' + this.paymentDebitTabs[newVal].name);
      } catch(err) {
        console.log('try setting the tab name but something goes wrong')
      }
    }
  },
  computed: {
  },
  settings: {
    taxForSaloon: 'taxForSaloon'
  }
};
</script>