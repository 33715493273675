<template>
  <v-form v-model="formValid">
    <template>
      <v-subheader class="font-italic font-weight-light py-1"
        >Documento commerciale di riferimento</v-subheader
      >
      <PaymentTransactionsTable
        v-model="billLog"
        :bus="billLogsTableBus"
        @input="handleBillSelection"
        :showDialogRowClicked="false"
        :single-select="true"
        :noInvoice="true"
        :list-bills="true"
      ></PaymentTransactionsTable>
      <v-subheader class="font-italic font-weight-light py-1"
        >Dettaglio Scontrino</v-subheader
      >
      <TypeDataTable
        v-model="itemsToRemoveSelected"
        :headers="headersTableRemove"
        :items="itemsToRemove"
        height="calc(400 - 100px)"
        :show-select="true"
        :show-actions="false"
        :single-select="false"
        :select-on-row-click="false"
        itemKey="tableId"
        @input="calcNewAmount"
      >
        <template v-slot:custom-description="{ item }">
          <div v-if="!!item.serviceId">
            {{ item.name }}
          </div>
          <div v-else-if="!!item.itemId">
            {{ item.description }}
          </div>
        </template>

        <template v-slot:custom-operators="{ item }">
          <ul v-if="!!item.operators">
            <li v-for="value in item.operators" :key="value.id">
              {{ value.firstname }} {{ value.lastname }}
            </li>
          </ul>
          <div v-else>
            <ul>
              <li>Non Presenti</li>
            </ul>
          </div>
        </template>
        <template v-slot:custom-qtyToRemove="{ item }">
          <v-text-field
            type="number"
            dense
            filled
            hide-details="auto" 
            v-model="item.qtyToRemove"
            :rules="[qtyToRemoveCheck(item)]"
            @input="qtyToRemoveCheck(item)"
            :disabled="item.quantity == item.qtyAlreadyRemoved ? true : false"
          >
          </v-text-field>
        </template>
        <template v-slot:custom-quantity="{ item }">
          <v-text-field
            type="text"
            dense
            filled
            disabled
            hide-details="auto"
            :value="item.quantity"
          >
          </v-text-field>
        </template> 
        <template v-slot:custom-qtyAlreadyRemoved="{ item }">
          <v-text-field
            type="text"
            dense
            filled
            disabled
            hide-details="auto"
            :value="item.qtyAlreadyRemoved"
          >
          </v-text-field>
        </template>   
        <template v-slot:custom-price="{ item }">
          <v-text-field
            type="text"
            dense
            filled
            disabled
            hide-details="auto"
            :value="item.price + ' €'"
          >
          </v-text-field>
        </template>  
      </TypeDataTable>
      <v-subheader class="font-italic font-weight-light py-1">Dati</v-subheader>
      <v-row no-gutters class="py-1">
        <v-col :class="{'pr-2': !!customer}">
          <v-text-field
            v-model="amount"
            :rules="[greaterThan0]"
            dense
            filled
            label="Ammontare"
            readonly
            hide-details="auto"
          ></v-text-field>
        </v-col>
        <v-col v-if="!!customer">
          <v-select
            v-model="customer"
            :item-text="() => customer.firstname + ' ' + customer.lastname"
            item-value="id"
            label="Cliente"
            :items="!!customer ? [customer] : []"
            dense
            filled
            hide-details="auto"
            readonly
          ></v-select>
        </v-col>
      </v-row>
      <v-row no-gutters class="py-1">
        <v-col>
          <v-textarea
            v-model="reason"
            label="Motivazione"
            filled
            hide-details="auto"
            @input="updateField('reason', $event)"
          ></v-textarea>
        </v-col>
      </v-row>
    </template>
  </v-form>
</template>

<script>
import Vue from "vue";
import paymentDebitForm from "@/services/paymentDebits/paymentDebit.form.js";
import NumericKeyboardInput from "@/components/common/NumericKeyboardInput.vue";
import DatePicker from "@/components/common/DatePickerCommon.vue";
import PaymentTransactionsTable from "@/components/areas/payments/paymentTransactions/PaymentTransactionsTable.vue";
import TypeDataTable from "@/components/common/TypeDataTable.vue";
import billService from "@/services/bills/bills.service";

export default {
  name: "GeneralFormTab",
  components: {
    DatePicker,
    NumericKeyboardInput,
    PaymentTransactionsTable,
    TypeDataTable,
  },
  data: function () {
    return {
      amount: undefined,
      customer: undefined,
      operator: undefined,
      reason: undefined,
      loadingPaymentDebit: false,
      type: undefined,
      documentType: undefined,
      billLog: undefined,
      formValid: false,
      qtyToRemove: undefined,
      billLogsTableBus: new Vue(),
      itemsToRemove: [],
      itemsToRemoveSelected: [],
      dinamicRule: true,
      documentNumber: undefined,
      createdAt: undefined,
      max255CharRule: (v) => {
        if (v) {
          return v.length <= 255 || "Al massimo 255 caratteri";
        } else {
          return true;
        }
      },
      presenceRule: (v) => {
        return !!v || "Il campo è obbligatorio";
      },
      
      greaterThan0: (v) => {
        return v > 0 || "Inserisci Quantità Corretta"
      },

    };
  },
  props: {
    bus: {
      type: Object,
      default: function () {
        return new Vue();
      },
    },
  },
  mounted: function () {
    this.handleObjectChanges(paymentDebitForm.paymentDebit);

    var self = this;
    paymentDebitForm.on("update", function (data) {
      self.handleObjectChanges(data.paymentDebit);
    });
    this.updateField("documentType", "receipt");
  },
  methods: {
    handleObjectChanges(paymentDebit) {
      if (!!paymentDebit) {
      }
    },
    updateField(key, value) {
      paymentDebitForm.updateField(key, value);
    },
    handleBillSelection(newBillLog) {
      if(!!newBillLog && newBillLog.length > 0)
        billService.getPaymentDebits(newBillLog[0].bill).then((paymentDebitsLinkedToBill) => {
        this.itemsToRemove = [];
        this.itemsToRemoveSelected = [];
        let i = 0;

        for (const item of newBillLog[0].info.items) {        
          i++;
          item.tableId = i;
          item.qtyAlreadyRemoved = 0
          item.qtyToRemove = 0  
          item.qtyRule = true
          let entityId 
          if(!!item.serviceId && paymentDebitsLinkedToBill.length > 0) entityId = 'serviceId'
          else if(!!item.itemId && paymentDebitsLinkedToBill.length > 0) entityId = 'itemId'
          if(!!entityId){
            for (const paymentDebitLinked of paymentDebitsLinkedToBill){
              const result = paymentDebitLinked.info.items.find(el => el[entityId] == item[entityId])
              if(!!result) item.qtyAlreadyRemoved += Number(result.quantity)
            }
          }
          if(!!item.serviceId)
            item.price = newBillLog[0].bill.billServices.find(el => el.serviceId == item.serviceId).price
          else
            item.price = newBillLog[0].bill.billItems.find(el => el.itemId == item.itemId).price

          this.itemsToRemove.push(item);
        }

        if(!!newBillLog[0].info && !!newBillLog[0].info.customers && newBillLog[0].info.customers.length > 0) {
          this.customer = newBillLog[0].info.customers[0]
          this.updateField("customerId", this.customer.id);
        }
        if (
          !!newBillLog &&
          Array.isArray(newBillLog) &&
          newBillLog.length > 0
        ) {
          this.updateField("bill", {
            id: newBillLog[0].bill.id,
          });
        }
         if(!!newBillLog[0].info && !!newBillLog[0].info.documentNumber){
          this.documentNumber = newBillLog[0].info.documentNumber
          this.updateField("documentNumber", this.documentNumber);
        }
         if(!!newBillLog[0].createdAt){
          const options = {'year':'numeric', 'month': '2-digit', 'day': '2-digit'};
          this.createdAt = new Date(newBillLog[0].createdAt).toLocaleDateString("it",options)
          this.createdAt = this.createdAt.replace('/','-')
          this.createdAt = this.createdAt.replace('/','-')
          this.updateField("createdAt", this.createdAt);
        }
      });
    },
    calcNewAmount() {
      if(!!this.itemsToRemoveSelected){
        let amountToCalc = 0
        let itemsRemoved = []
        let info = {}
        for(const item of this.itemsToRemoveSelected){
          let obj = {}
          if(!!item.itemId){
            amountToCalc += Number(item.price) * Number(item.qtyToRemove)
            obj.itemId = item.itemId
            obj.price = item.price
            obj._type = 'item'
            obj.description = item.description
          }
          else if(!!item.serviceId){
            amountToCalc += Number(item.price) * Number(item.qtyToRemove)
            obj.serviceId = item.serviceId
            obj.price = item.price
            obj._type = 'service'
            obj.name = item.name
          }
          obj.tax = item.tax  
          obj.quantity = Number(item.qtyToRemove)
          itemsRemoved.push(obj)
        }
        this.amount = amountToCalc.toFixed(2)
        info.items = itemsRemoved
        this.updateField("amount", this.amount)
        this.updateField("info", info)
      }
    },
    qtyToRemoveCheck(v) {
      this.calcNewAmount()
      return ((Number(v.quantity) >= (Number(v.qtyAlreadyRemoved) + Number(v.qtyToRemove)))&& Number(v.qtyToRemove) >= 0) || "Inserisci Quantità Corretta"
    },
  },
  
  watch: {
    formValid(newVal) {
      this.$emit("update:valid", newVal);
      if (this.bus) {
        this.bus.$emit("update:valid", newVal);
      }
      paymentDebitForm.setValid(newVal);
    },
  },
  computed: {
    headersTableRemove() {
      return [
        { text: "Descrizione", value: "description", type: "custom" },
        { text: "Prezzo", value: "price", type: "custom" },
        { text: "Quantità Ordinata", value: "quantity" , type: "custom" },
        { text: "Quantità Stornata", value: "qtyAlreadyRemoved", type:"custom" },
        { text: "Quantità da Stornare", value: "qtyToRemove", type: "custom" },
        { text: "Operatori", value: "operators", type: "custom" },
      ];
    },
  },
};
</script>